/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */

.spectrum--darkest {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1.0;
  --spectrum-gray-50: rgb(0, 0, 0);
  --spectrum-gray-75: rgb(14, 14, 14);
  --spectrum-gray-100: rgb(29, 29, 29);
  --spectrum-gray-200: rgb(48, 48, 48);
  --spectrum-gray-300: rgb(75, 75, 75);
  --spectrum-gray-400: rgb(106, 106, 106);
  --spectrum-gray-500: rgb(141, 141, 141);
  --spectrum-gray-600: rgb(176, 176, 176);
  --spectrum-gray-700: rgb(208, 208, 208);
  --spectrum-gray-800: rgb(235, 235, 235);
  --spectrum-gray-900: rgb(255, 255, 255);
  --spectrum-red-100: rgb(87, 0, 0);
  --spectrum-red-200: rgb(110, 0, 0);
  --spectrum-red-300: rgb(138, 0, 0);
  --spectrum-red-400: rgb(167, 0, 0);
  --spectrum-red-500: rgb(196, 7, 6);
  --spectrum-red-600: rgb(221, 33, 24);
  --spectrum-red-700: rgb(238, 67, 49);
  --spectrum-red-800: rgb(249, 99, 76);
  --spectrum-red-900: rgb(255, 129, 107);
  --spectrum-red-1000: rgb(255, 158, 140);
  --spectrum-red-1100: rgb(255, 183, 169);
  --spectrum-red-1200: rgb(255, 205, 195);
  --spectrum-red-1300: rgb(255, 223, 217);
  --spectrum-red-1400: rgb(255, 237, 234);
  --spectrum-orange-100: rgb(72, 24, 1);
  --spectrum-orange-200: rgb(92, 32, 0);
  --spectrum-orange-300: rgb(115, 43, 0);
  --spectrum-orange-400: rgb(138, 55, 0);
  --spectrum-orange-500: rgb(162, 68, 0);
  --spectrum-orange-600: rgb(186, 82, 0);
  --spectrum-orange-700: rgb(210, 98, 0);
  --spectrum-orange-800: rgb(232, 116, 0);
  --spectrum-orange-900: rgb(249, 137, 23);
  --spectrum-orange-1000: rgb(255, 162, 59);
  --spectrum-orange-1100: rgb(255, 188, 102);
  --spectrum-orange-1200: rgb(253, 210, 145);
  --spectrum-orange-1300: rgb(255, 226, 181);
  --spectrum-orange-1400: rgb(255, 239, 213);
  --spectrum-yellow-100: rgb(53, 36, 0);
  --spectrum-yellow-200: rgb(68, 47, 0);
  --spectrum-yellow-300: rgb(86, 62, 0);
  --spectrum-yellow-400: rgb(103, 77, 0);
  --spectrum-yellow-500: rgb(122, 92, 0);
  --spectrum-yellow-600: rgb(141, 108, 0);
  --spectrum-yellow-700: rgb(161, 126, 0);
  --spectrum-yellow-800: rgb(180, 144, 0);
  --spectrum-yellow-900: rgb(199, 162, 0);
  --spectrum-yellow-1000: rgb(216, 181, 0);
  --spectrum-yellow-1100: rgb(233, 199, 0);
  --spectrum-yellow-1200: rgb(247, 216, 4);
  --spectrum-yellow-1300: rgb(249, 233, 97);
  --spectrum-yellow-1400: rgb(252, 243, 170);
  --spectrum-chartreuse-100: rgb(32, 43, 0);
  --spectrum-chartreuse-200: rgb(42, 56, 0);
  --spectrum-chartreuse-300: rgb(54, 72, 0);
  --spectrum-chartreuse-400: rgb(66, 88, 0);
  --spectrum-chartreuse-500: rgb(79, 105, 0);
  --spectrum-chartreuse-600: rgb(93, 123, 0);
  --spectrum-chartreuse-700: rgb(107, 142, 0);
  --spectrum-chartreuse-800: rgb(122, 161, 0);
  --spectrum-chartreuse-900: rgb(138, 180, 3);
  --spectrum-chartreuse-1000: rgb(154, 198, 11);
  --spectrum-chartreuse-1100: rgb(170, 216, 22);
  --spectrum-chartreuse-1200: rgb(187, 232, 41);
  --spectrum-chartreuse-1300: rgb(205, 246, 72);
  --spectrum-chartreuse-1400: rgb(225, 253, 132);
  --spectrum-celery-100: rgb(0, 47, 7);
  --spectrum-celery-200: rgb(0, 61, 9);
  --spectrum-celery-300: rgb(0, 77, 12);
  --spectrum-celery-400: rgb(0, 95, 15);
  --spectrum-celery-500: rgb(0, 113, 15);
  --spectrum-celery-600: rgb(0, 132, 15);
  --spectrum-celery-700: rgb(0, 151, 20);
  --spectrum-celery-800: rgb(13, 171, 37);
  --spectrum-celery-900: rgb(45, 191, 58);
  --spectrum-celery-1000: rgb(80, 208, 82);
  --spectrum-celery-1100: rgb(115, 224, 107);
  --spectrum-celery-1200: rgb(147, 237, 131);
  --spectrum-celery-1300: rgb(180, 247, 162);
  --spectrum-celery-1400: rgb(213, 252, 202);
  --spectrum-green-100: rgb(10, 44, 28);
  --spectrum-green-200: rgb(7, 59, 36);
  --spectrum-green-300: rgb(0, 76, 46);
  --spectrum-green-400: rgb(0, 93, 57);
  --spectrum-green-500: rgb(0, 111, 69);
  --spectrum-green-600: rgb(0, 130, 82);
  --spectrum-green-700: rgb(0, 149, 98);
  --spectrum-green-800: rgb(28, 168, 114);
  --spectrum-green-900: rgb(52, 187, 132);
  --spectrum-green-1000: rgb(75, 205, 149);
  --spectrum-green-1100: rgb(103, 222, 168);
  --spectrum-green-1200: rgb(137, 236, 188);
  --spectrum-green-1300: rgb(177, 244, 209);
  --spectrum-green-1400: rgb(214, 249, 228);
  --spectrum-seafoam-100: rgb(18, 43, 42);
  --spectrum-seafoam-200: rgb(19, 57, 55);
  --spectrum-seafoam-300: rgb(16, 73, 70);
  --spectrum-seafoam-400: rgb(3, 91, 88);
  --spectrum-seafoam-500: rgb(0, 108, 104);
  --spectrum-seafoam-600: rgb(0, 127, 121);
  --spectrum-seafoam-700: rgb(0, 146, 140);
  --spectrum-seafoam-800: rgb(0, 165, 159);
  --spectrum-seafoam-900: rgb(26, 185, 178);
  --spectrum-seafoam-1000: rgb(66, 202, 195);
  --spectrum-seafoam-1100: rgb(102, 218, 211);
  --spectrum-seafoam-1200: rgb(139, 232, 225);
  --spectrum-seafoam-1300: rgb(179, 242, 237);
  --spectrum-seafoam-1400: rgb(215, 248, 244);
  --spectrum-cyan-100: rgb(0, 41, 68);
  --spectrum-cyan-200: rgb(0, 54, 88);
  --spectrum-cyan-300: rgb(0, 69, 108);
  --spectrum-cyan-400: rgb(0, 86, 128);
  --spectrum-cyan-500: rgb(0, 103, 147);
  --spectrum-cyan-600: rgb(0, 121, 167);
  --spectrum-cyan-700: rgb(0, 140, 186);
  --spectrum-cyan-800: rgb(4, 160, 205);
  --spectrum-cyan-900: rgb(23, 180, 221);
  --spectrum-cyan-1000: rgb(57, 199, 234);
  --spectrum-cyan-1100: rgb(96, 216, 243);
  --spectrum-cyan-1200: rgb(134, 230, 250);
  --spectrum-cyan-1300: rgb(170, 242, 255);
  --spectrum-cyan-1400: rgb(206, 249, 255);
  --spectrum-blue-100: rgb(0, 38, 81);
  --spectrum-blue-200: rgb(0, 50, 106);
  --spectrum-blue-300: rgb(0, 64, 135);
  --spectrum-blue-400: rgb(0, 78, 166);
  --spectrum-blue-500: rgb(0, 92, 200);
  --spectrum-blue-600: rgb(6, 108, 231);
  --spectrum-blue-700: rgb(29, 128, 245);
  --spectrum-blue-800: rgb(64, 150, 243);
  --spectrum-blue-900: rgb(94, 170, 247);
  --spectrum-blue-1000: rgb(124, 189, 250);
  --spectrum-blue-1100: rgb(152, 206, 253);
  --spectrum-blue-1200: rgb(179, 222, 254);
  --spectrum-blue-1300: rgb(206, 234, 255);
  --spectrum-blue-1400: rgb(227, 243, 255);
  --spectrum-indigo-100: rgb(26, 29, 97);
  --spectrum-indigo-200: rgb(35, 39, 125);
  --spectrum-indigo-300: rgb(46, 50, 158);
  --spectrum-indigo-400: rgb(58, 63, 189);
  --spectrum-indigo-500: rgb(73, 78, 216);
  --spectrum-indigo-600: rgb(90, 96, 235);
  --spectrum-indigo-700: rgb(110, 115, 246);
  --spectrum-indigo-800: rgb(132, 136, 253);
  --spectrum-indigo-900: rgb(153, 157, 255);
  --spectrum-indigo-1000: rgb(174, 177, 255);
  --spectrum-indigo-1100: rgb(194, 196, 255);
  --spectrum-indigo-1200: rgb(212, 213, 255);
  --spectrum-indigo-1300: rgb(227, 228, 255);
  --spectrum-indigo-1400: rgb(240, 240, 255);
  --spectrum-purple-100: rgb(50, 16, 104);
  --spectrum-purple-200: rgb(67, 13, 140);
  --spectrum-purple-300: rgb(86, 16, 173);
  --spectrum-purple-400: rgb(106, 29, 200);
  --spectrum-purple-500: rgb(126, 49, 222);
  --spectrum-purple-600: rgb(145, 70, 236);
  --spectrum-purple-700: rgb(162, 94, 246);
  --spectrum-purple-800: rgb(178, 119, 250);
  --spectrum-purple-900: rgb(192, 143, 252);
  --spectrum-purple-1000: rgb(206, 166, 253);
  --spectrum-purple-1100: rgb(219, 188, 254);
  --spectrum-purple-1200: rgb(230, 207, 255);
  --spectrum-purple-1300: rgb(240, 224, 255);
  --spectrum-purple-1400: rgb(248, 237, 255);
  --spectrum-fuchsia-100: rgb(70, 14, 68);
  --spectrum-fuchsia-200: rgb(93, 9, 92);
  --spectrum-fuchsia-300: rgb(120, 0, 120);
  --spectrum-fuchsia-400: rgb(146, 0, 147);
  --spectrum-fuchsia-500: rgb(169, 19, 170);
  --spectrum-fuchsia-600: rgb(191, 43, 191);
  --spectrum-fuchsia-700: rgb(211, 65, 213);
  --spectrum-fuchsia-800: rgb(228, 91, 229);
  --spectrum-fuchsia-900: rgb(239, 120, 238);
  --spectrum-fuchsia-1000: rgb(246, 149, 243);
  --spectrum-fuchsia-1100: rgb(251, 175, 246);
  --spectrum-fuchsia-1200: rgb(254, 199, 248);
  --spectrum-fuchsia-1300: rgb(255, 220, 250);
  --spectrum-fuchsia-1400: rgb(255, 235, 252);
  --spectrum-magenta-100: rgb(83, 3, 41);
  --spectrum-magenta-200: rgb(106, 0, 52);
  --spectrum-magenta-300: rgb(133, 0, 65);
  --spectrum-magenta-400: rgb(161, 0, 78);
  --spectrum-magenta-500: rgb(186, 22, 93);
  --spectrum-magenta-600: rgb(209, 43, 114);
  --spectrum-magenta-700: rgb(227, 69, 137);
  --spectrum-magenta-800: rgb(241, 97, 156);
  --spectrum-magenta-900: rgb(252, 124, 173);
  --spectrum-magenta-1000: rgb(255, 152, 191);
  --spectrum-magenta-1100: rgb(255, 179, 207);
  --spectrum-magenta-1200: rgb(255, 202, 221);
  --spectrum-magenta-1300: rgb(255, 221, 233);
  --spectrum-magenta-1400: rgb(255, 236, 243);
  /* legacy color aliases matching closest v6 color to v5 name */
  --spectrum-legacy-color-celery-400: var(--spectrum-celery-800);
  --spectrum-legacy-color-celery-500: var(--spectrum-celery-900);
  --spectrum-legacy-color-celery-600: var(--spectrum-celery-1000);
  --spectrum-legacy-color-celery-700: var(--spectrum-celery-1100);
  --spectrum-legacy-color-chartreuse-400: var(--spectrum-chartreuse-900);
  --spectrum-legacy-color-chartreuse-500: var(--spectrum-chartreuse-1000);
  --spectrum-legacy-color-chartreuse-600: var(--spectrum-chartreuse-1100);
  --spectrum-legacy-color-chartreuse-700: var(--spectrum-chartreuse-1200);
  --spectrum-legacy-color-yellow-400: var(--spectrum-yellow-1000);
  --spectrum-legacy-color-yellow-500: var(--spectrum-yellow-1100);
  --spectrum-legacy-color-yellow-600: var(--spectrum-yellow-1200);
  --spectrum-legacy-color-yellow-700: var(--spectrum-yellow-1300);
  --spectrum-legacy-color-magenta-400: var(--spectrum-magenta-600);
  --spectrum-legacy-color-magenta-500: var(--spectrum-magenta-700);
  --spectrum-legacy-color-magenta-600: var(--spectrum-magenta-800);
  --spectrum-legacy-color-magenta-700: var(--spectrum-magenta-900);
  --spectrum-legacy-color-fuchsia-400: var(--spectrum-fuchsia-600);
  --spectrum-legacy-color-fuchsia-500: var(--spectrum-fuchsia-700);
  --spectrum-legacy-color-fuchsia-600: var(--spectrum-fuchsia-800);
  --spectrum-legacy-color-fuchsia-700: var(--spectrum-fuchsia-900);
  --spectrum-legacy-color-purple-400: var(--spectrum-purple-600);
  --spectrum-legacy-color-purple-500: var(--spectrum-purple-700);
  --spectrum-legacy-color-purple-600: var(--spectrum-purple-800);
  --spectrum-legacy-color-purple-700: var(--spectrum-purple-900);
  --spectrum-legacy-color-indigo-400: var(--spectrum-indigo-600);
  --spectrum-legacy-color-indigo-500: var(--spectrum-indigo-700);
  --spectrum-legacy-color-indigo-600: var(--spectrum-indigo-800);
  --spectrum-legacy-color-indigo-700: var(--spectrum-indigo-900);
  --spectrum-legacy-color-red-400: var(--spectrum-red-600);
  --spectrum-legacy-color-red-500: var(--spectrum-red-700);
  --spectrum-legacy-color-red-600: var(--spectrum-red-800);
  --spectrum-legacy-color-red-700: var(--spectrum-red-900);
  --spectrum-legacy-color-orange-400: var(--spectrum-orange-800);
  --spectrum-legacy-color-orange-500: var(--spectrum-orange-900);
  --spectrum-legacy-color-orange-600: var(--spectrum-orange-1000);
  --spectrum-legacy-color-orange-700: var(--spectrum-orange-1100);
  --spectrum-legacy-color-green-400: var(--spectrum-green-700);
  --spectrum-legacy-color-green-500: var(--spectrum-green-800);
  --spectrum-legacy-color-green-600: var(--spectrum-green-900);
  --spectrum-legacy-color-green-700: var(--spectrum-green-1000);
  --spectrum-legacy-color-blue-400: var(--spectrum-blue-700);
  --spectrum-legacy-color-blue-500: var(--spectrum-blue-800);
  --spectrum-legacy-color-blue-600: var(--spectrum-blue-900);
  --spectrum-legacy-color-blue-700: var(--spectrum-blue-1000);
  --spectrum-alias-background-color-modal-overlay: rgba(0,0,0,0.6);
  --spectrum-alias-dropshadow-color: rgba(0,0,0,0.8);
  --spectrum-alias-background-color-hover-overlay: rgba(255,255,255,0.08);
  --spectrum-alias-highlight-hover: rgba(255,255,255,0.08);
  --spectrum-alias-highlight-active: rgba(239,239,239,0.15);
  --spectrum-alias-highlight-selected: rgba(64,150,243,0.2);
  --spectrum-alias-highlight-selected-hover: rgba(64,150,243,0.3);
  --spectrum-alias-highlight-invalid: rgba(221, 33, 24, 0.15); /* matches red-600 */
  --spectrum-alias-text-highlight-color: rgba(64,150,243,0.3);
  --spectrum-alias-background-color-quickactions: rgba(29,29,29,0.9);
  --spectrum-alias-radial-reaction-color-default: rgba(235,235,235,0.6);
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-50);
  --spectrum-colorarea-border-color: rgba(239,239,239,0.1);
  --spectrum-colorarea-border-color-hover: rgba(239,239,239,0.1);
  --spectrum-colorarea-border-color-down: rgba(239,239,239,0.1);
  --spectrum-colorarea-border-color-key-focus: rgba(239,239,239,0.1);
  --spectrum-colorslider-border-color: rgba(239,239,239,0.1);
  --spectrum-colorslider-border-color-hover: rgba(239,239,239,0.1);
  --spectrum-colorslider-border-color-down: rgba(239,239,239,0.1);
  --spectrum-colorslider-border-color-key-focus: rgba(239,239,239,0.1);
  --spectrum-colorslider-vertical-border-color: rgba(239,239,239,0.1);
  --spectrum-colorslider-vertical-border-color-hover: rgba(239,239,239,0.1);
  --spectrum-colorslider-vertical-border-color-down: rgba(239,239,239,0.1);
  --spectrum-colorslider-vertical-border-color-key-focus: rgba(239,239,239,0.1);
  --spectrum-colorwheel-border-color: rgba(239,239,239,0.1);
  --spectrum-colorwheel-border-color-hover: rgba(239,239,239,0.1);
  --spectrum-colorwheel-border-color-down: rgba(239,239,239,0.1);
  --spectrum-colorwheel-border-color-key-focus: rgba(239,239,239,0.1);
  --spectrum-miller-column-item-background-color-selected: rgba(38,128,235,0.1);
  --spectrum-miller-column-item-background-color-selected-hover: rgba(38,128,235,0.2);
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: rgba(235,235,235,0.02);
  --spectrum-well-border-color: rgba(239,239,239,0.05);
  /* BEGIN RSP PATCH */
  /* missing in spectrum-css */
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-100);
  /* END RSP PATCH */
  /* react spectrum additions */

  --spectrum-accent-background-color-default: var(--spectrum-accent-color-600);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-500);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-400);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-500);

  --spectrum-neutral-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-300);

  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-300);

  --spectrum-negative-background-color-default: var(--spectrum-red-600);
  --spectrum-negative-background-color-hover: var(--spectrum-red-500);
  --spectrum-negative-background-color-down: var(--spectrum-red-400);
  --spectrum-negative-background-color-key-focus: var(--spectrum-red-500);

  --spectrum-positive-background-color-default: var(--spectrum-green-600);
  --spectrum-positive-background-color-hover: var(--spectrum-green-500);
  --spectrum-positive-background-color-down: var(--spectrum-green-400);
  --spectrum-positive-background-color-key-focus: var(--spectrum-green-500);

  --spectrum-informative-background-color-default: var(--spectrum-blue-600);
  --spectrum-informative-background-color-hover: var(--spectrum-blue-500);
  --spectrum-informative-background-color-down: var(--spectrum-blue-400);
  --spectrum-informative-background-color-key-focus: var(--spectrum-blue-500);

  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-700);
  --spectrum-orange-background-color-default: var(--spectrum-orange-800);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-1000);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-900);
  --spectrum-celery-background-color-default: var(--spectrum-celery-800);
  --spectrum-green-background-color-default: var(--spectrum-green-700);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-700);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-700);
  --spectrum-blue-background-color-default: var(--spectrum-blue-700);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-700);
  --spectrum-purple-background-color-default: var(--spectrum-purple-700);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-700);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-700);

  --spectrum-negative-visual-color: var(--spectrum-red-700);
  --spectrum-positive-visual-color: var(--spectrum-green-800);
  --spectrum-notice-visual-color: var(--spectrum-orange-900);
  --spectrum-informative-visual-color: var(--spectrum-blue-900);

  --spectrum-gray-visual-color: var(--spectrum-gray-600);
  --spectrum-red-visual-color: var(--spectrum-red-700);
  --spectrum-orange-visual-color: var(--spectrum-orange-900);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-1100);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-900);
  --spectrum-celery-visual-color: var(--spectrum-celery-800);
  --spectrum-green-visual-color: var(--spectrum-green-800);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-800);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-900);
  --spectrum-blue-visual-color: var(--spectrum-blue-900);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-900);
  --spectrum-purple-visual-color: var(--spectrum-purple-900);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-900);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-900);
}
